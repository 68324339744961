import React, { useCallback } from "react";
import { Layout } from "../../components/Layout";
import { useShowCookiePreferences } from "../../components/Layout/CookieContainer";
import { Container } from "../../components/Section";
import { Seo } from "../../components/Seo";

const CookieContent: React.FC = () => {
  const showCookiePreferences = useShowCookiePreferences();

  const handleCookiePreferences = useCallback(
    (e) => {
      e.preventDefault();
      showCookiePreferences();
    },
    [showCookiePreferences]
  );

  return (
    <>
      <div data-custom-class="body">
        <h1>COOKIE POLICY</h1>
        <strong>Last updated December 23, 2021</strong>
        <br />
        <br />
        <p>
          This Cookie Policy explains how LeanyLabs O&Uuml; ("
          <strong>Company</strong>", "<strong>we</strong>", "<strong>us</strong>
          ", and "<strong>our</strong>") uses cookies and similar technologies
          to recognize you when you visit our websites at{" "}
          <a
            href="https://leanylabs.com"
            target="_blank"
            data-custom-class="link"
          >
            https://leanylabs.com
          </a>
          , ("<strong>Websites</strong>"). It explains what these technologies
          are and why we use them, as well as your rights to control our use of
          them.
        </p>
        <p>
          In some cases we may use cookies to collect personal information, or
          that becomes personal information if we combine it with other
          information.
        </p>
        <h3>What are cookies?</h3>
        <p>
          Cookies are small data files that are placed on your computer or
          mobile device when you visit a website. Cookies are widely used by
          website owners in order to make their websites work, or to work more
          efficiently, as well as to provide reporting information.
        </p>
        <p>
          Cookies set by the website owner (in this case, LeanyLabs O&Uuml;) are
          called "first party cookies". Cookies set by parties other than the
          website owner are called "third party cookies". Third party cookies
          enable third party features or functionality to be provided on or
          through the website (e.g. like advertising, interactive content and
          analytics). The parties that set these third party cookies can
          recognize your computer both when it visits the website in question
          and also when it visits certain other websites.
        </p>
        <h3>Why do we use cookies?</h3>
        <p>
          We use first and third party cookies for several reasons. Some cookies
          are required for technical reasons in order for our Websites to
          operate, and we refer to these as "essential" or "strictly necessary"
          cookies. Other cookies also enable us to track and target the
          interests of our users to enhance the experience on our Online
          Properties. Third parties serve cookies through our Websites for
          advertising, analytics and other purposes. This is described in more
          detail below.
        </p>
        <p>
          The specific types of first and third party cookies served through our
          Websites and the purposes they perform are described below (please
          note that the specific cookies served may vary depending on the
          specific Online Properties you visit):
        </p>
        <h3>How can I control cookies?</h3>
        <p>
          You have the right to decide whether to accept or reject cookies. You
          can exercise your cookie rights by setting your preferences in the{" "}
          <a href="#" onClick={handleCookiePreferences}>
            Cookie Preferences
          </a>
          . The Cookie Preferences allows you to select which categories of
          cookies you accept or reject. Essential cookies cannot be rejected as
          they are strictly necessary to provide you with services.
        </p>
        <p>
          The Cookie Preferences can be found in the notification banner and on
          our website. If you choose to reject cookies, you may still use our
          website though your access to some functionality and areas of our
          website may be restricted. You may also set or amend your web browser
          controls to accept or refuse cookies. As the means by which you can
          refuse cookies through your web browser controls vary from
          browser-to-browser, you should visit your browser's help menu for more
          information.
        </p>
        <p>
          In addition, most advertising networks offer you a way to opt out of
          targeted advertising. If you would like to find out more information,
          please visit&nbsp;
          <a
            href="http://www.aboutads.info/choices/"
            target="_blank"
            data-custom-class="link"
          >
            http://www.aboutads.info/choices/
          </a>
          &nbsp;or&nbsp;
          <a
            href="http://www.youronlinechoices.com"
            target="_blank"
            data-custom-class="link"
            data-fr-linked="true"
          >
            http://www.youronlinechoices.com
          </a>
          .
        </p>
        <p>
          The specific types of first and third party cookies served through our
          Websites and the purposes they perform are described in the table
          below (please note that the specific&nbsp;cookies served may vary
          depending on the specific Online Properties you visit):
        </p>
        <section>
          <br />
          <h4 id="cookies-essential">Essential website cookies:</h4>
          <p>
            These cookies are strictly necessary to provide you with services
            available through our Websites and to use some of its features, such
            as access to secure areas.
          </p>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>COMPASS</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>Ensures the operation of reCAPTCHA*</td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>.docs.google.com</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  Google{" "}
                  <a href="https://policies.google.com/privacy" target="_blank">
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>http_cookie</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>1 hour</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>__tlbcpv</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>
                  Used to record unique visitor views of the consent banner.
                </td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>.termly.io</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  Termly{" "}
                  <a
                    href="https://termly.io/our-privacy-policy/"
                    target="_blank"
                  >
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>http_cookie</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>1 year</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <br />
          <h4 id="cookies-performance">
            Performance and functionality cookies:
          </h4>
          <p>
            These cookies are used to enhance the performance and functionality
            of our Websites but are non-essential to their use. However, without
            these cookies, certain functionality (like videos) may become
            unavailable.
          </p>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>__cfruid</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>
                  Used by the content network, Cloudflare, to identify trusted
                  web traffic.
                </td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>.cdpn.io</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  Cloudflare{" "}
                  <a
                    href="https://www.cloudflare.com/privacypolicy/"
                    target="_blank"
                  >
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>server_cookie</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>session</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>__cfruid</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>
                  Used by the content network, Cloudflare, to identify trusted
                  web traffic.
                </td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>.codepen.io</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  Cloudflare{" "}
                  <a
                    href="https://www.cloudflare.com/privacypolicy/"
                    target="_blank"
                  >
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>server_cookie</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>session</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <br />
          <h4 id="cookies-analytics">Analytics and customization cookies:</h4>
          <p>
            These cookies collect information that is used either in aggregate
            form to help us understand how our Websites are being used or how
            effective our marketing campaigns are, or to help us customize our
            Websites for you.
          </p>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>#collect</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>
                  Sends data such as visitor&rsquo;s behavior and device to
                  Google Analytics. It is able to keep track of the visitor
                  across marketing channels and devices. It is a pixel tracker
                  type cookie whose activity lasts within the browsing session.
                </td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>leanylabs.com</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  Google Analytics{" "}
                  <a
                    href="https://policies.google.com/technologies/ads"
                    target="_blank"
                  >
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>pixel_tracker</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>session</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>_gid</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>
                  Keeps an entry of unique ID which is then used to come up with
                  statistical data on website usage by visitors. It is a HTTP
                  cookie type and expires after a browsing session.
                </td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>.leanylabs.com</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  Google Analytics{" "}
                  <a
                    href="https://policies.google.com/technologies/ads"
                    target="_blank"
                  >
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>http_cookie</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>1 day</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>_gat#</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>
                  Enables Google Analytics regulate the rate of requesting. It
                  is a HTTP cookie type that lasts for a session.
                </td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>.leanylabs.com</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  Google Analytics{" "}
                  <a
                    href="https://policies.google.com/technologies/ads"
                    target="_blank"
                  >
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>http_cookie</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>1 minute</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>_ga</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>
                  It records a particular ID used to come up with data about
                  website usage by the user. It is a HTTP cookie that expires
                  after 2 years.
                </td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>.leanylabs.com</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  Google Analytics{" "}
                  <a
                    href="https://policies.google.com/technologies/ads"
                    target="_blank"
                  >
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>http_cookie</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>1 year 11 months 29 days</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>S</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>
                  Sets a unique ID for the session. This allows the website to
                  obtain data on visitor behaviour for statistical purposes.
                </td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>.docs.google.com</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  Google{" "}
                  <a href="https://policies.google.com/privacy" target="_blank">
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>http_cookie</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>1 hour</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>NID</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>
                  Set by Google to set a unique user ID to remember user
                  preferences. Persistent cookie that stays for 182 days
                </td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>.google.com</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  Google{" "}
                  <a href="https://policies.google.com/privacy" target="_blank">
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>server_cookie</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>6 months</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>_ga_#</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>
                  Used to distinguish individual users by means of designation
                  of a randomly generated number as client identifier, which
                  allows calculation of visits and sessions
                </td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>.leanylabs.com</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  Google analytics{" "}
                  <a
                    href="https://policies.google.com/technologies/ads"
                    target="_blank"
                  >
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>http_cookie</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>1 year 11 months 29 days</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>__cf_bm</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>
                  This cookie is used to distinguish between humans and bots.
                  This is beneficial for the website, in order to make valid
                  reports on the use of their website.
                </td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>.codepen.io</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  CodePen{" "}
                  <a
                    href="https://blog.codepen.io/documentation/privacy-policy/"
                    target="_blank"
                  >
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>http_cookie</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>30 minutes</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <br />
          <h4 id="cookies-advertising">Advertising cookies:</h4>
          <p>
            These cookies are used to make advertising messages more relevant to
            you. They perform functions like preventing the same ad from
            continuously reappearing, ensuring that ads are properly displayed
            for advertisers, and in some cases selecting advertisements that are
            based on your interests.
          </p>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>VISITOR_INFO1_LIVE</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>
                  YouTube is a Google-owned platform for hosting and sharing
                  videos. YouTube collects user data through videos embedded in
                  websites, which is aggregated with profile data from other
                  Google services in order to display targeted advertising to
                  web visitors across a broad range of their own and other
                  websites. Used by Google in combination with SID to verify
                  Google user account and most recent login time.
                </td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>.youtube.com</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  YouTube{" "}
                  <a
                    href="https://www.youtube.com/static?template=privacy_guidelines&amp;gl=GB"
                    target="_blank"
                  >
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>http_cookie</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>5 months 27 days</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>YSC</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>
                  YouTube is a Google-owned platform for hosting and sharing
                  videos. YouTube collects user data through videos embedded in
                  websites, which is aggregated with profile data from other
                  Google services in order to display targeted advertising to
                  web visitors across a broad range of their own and other
                  websites. Used by Google in combination with SID to verify
                  Google user account and most recent login time.
                </td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>.youtube.com</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  YouTube{" "}
                  <a
                    href="https://www.youtube.com/static?template=privacy_guidelines&amp;gl=GB"
                    target="_blank"
                  >
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>http_cookie</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>session</td>
              </tr>
            </tbody>
          </table>
        </section>

        <section>
          <br />
          <h4 id="cookies-unclassified">Unclassified cookies:</h4>
          <p>
            These are cookies that have not yet been categorized. We are in the
            process of classifying these cookies with the help of their
            providers.
          </p>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>cp_session</td>
              </tr>
              <tr>
                <td>Purpose:</td>
                <td>__________</td>
              </tr>
              <tr>
                <td>Provider:</td>
                <td>codepen.io</td>
              </tr>
              <tr>
                <td>Service:</td>
                <td>
                  CodePen{" "}
                  <a
                    href="https://blog.codepen.io/documentation/privacy-policy/"
                    target="_blank"
                  >
                    View Service Privacy Policy
                  </a>{" "}
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Type:</td>
                <td>http_cookie</td>
              </tr>
              <tr>
                <td>Expires in:</td>
                <td>30 days</td>
              </tr>
            </tbody>
          </table>
        </section>
        <h3>What about other tracking technologies, like web beacons?</h3>
        <p>
          Cookies are not the only way&nbsp;to recognize or track visitors to a
          website. We may use other, similar technologies from time to time,
          like web beacons (sometimes called "tracking pixels" or "clear gifs").
          These are tiny graphics files that contain a unique identifier that
          enable us to recognize when someone has visited our Websites or opened
          an e-mail including them. This allows us, for example, to
          monitor&nbsp;the traffic patterns of users from one page within a
          website to another, to deliver or communicate with cookies, to
          understand whether you have come to the website from an online
          advertisement displayed on a third-party website, to improve site
          performance, and to measure the success of e-mail marketing campaigns.
          In many instances, these technologies are reliant on cookies to
          function properly, and so declining cookies will impair their
          functioning.
        </p>
        <h3>Do you use Flash cookies or Local Shared Objects?</h3>
        <p>
          Websites may also use so-called "Flash Cookies" (also known as Local
          Shared Objects or "LSOs") to, among other things, collect and store
          information about your use of our services, fraud prevention and for
          other site operations.
        </p>
        <p>
          If you do not want Flash Cookies stored on your computer, you can
          adjust the settings of your Flash player to block Flash Cookies
          storage using the tools contained in the&nbsp;
          <a
            href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
            target="_BLANK"
            data-custom-class="link"
          >
            Website Storage Settings Panel
          </a>
          . You can also control Flash Cookies by going to the&nbsp;
          <a
            href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
            target="_BLANK"
            data-custom-class="link"
          >
            Global Storage Settings Panel
          </a>
          &nbsp;and&nbsp;following the instructions (which may include
          instructions that explain, for example, how to delete existing Flash
          Cookies (referred to "information" on the Macromedia site), how to
          prevent Flash LSOs from being placed on your computer without your
          being asked, and (for Flash Player 8 and later) how to block Flash
          Cookies that are not being delivered by the operator of the page you
          are on at the time).
        </p>
        <p>
          Please note that setting the Flash Player to restrict or limit
          acceptance of Flash Cookies may reduce or impede the functionality of
          some Flash applications, including, potentially, Flash applications
          used in connection with our services or online content.
        </p>
        <h3>Do you serve targeted advertising?</h3>
        <p>
          Third parties may serve cookies on your computer or mobile device to
          serve advertising through our Websites. These companies may use
          information about your visits to this and other websites in order to
          provide relevant advertisements about goods and services that you may
          be interested in. They may also employ technology that is used to
          measure the effectiveness of advertisements. This can be accomplished
          by them using cookies or web beacons to collect information about your
          visits to this and other sites in order to provide relevant
          advertisements about goods and services of potential interest to you.
          The information collected through this process does not enable us or
          them to identify your name, contact details or other details that
          directly identify you unless you choose to provide these.
        </p>
        <h3>How often will you update this Cookie Policy?</h3>
        <p>
          We may update&nbsp;this Cookie Policy from time to time in order to
          reflect, for example, changes to the cookies we use or for other
          operational, legal or regulatory reasons. Please therefore re-visit
          this Cookie Policy regularly to stay informed about our use of cookies
          and related technologies.
        </p>
        <p>
          The date at the top of this Cookie Policy indicates when it was last
          updated.
        </p>
        <h3>Where can I get further information?</h3>
        <p>
          If you have any questions about our use of cookies or other
          technologies, please email us at hi@leanylabs.com or by post to:
        </p>
        <p>
          LeanyLabs O&Uuml;
          <br />
          Sepapaja tn 6<br />
          Tallinn, Harju maakond&nbsp;15551
          <br />
          Estonia
          <br />
          Phone:&nbsp;+380991731717
          <br />
        </p>
      </div>
    </>
  );
};

const Page: React.FC = () => (
  <Layout>
    <Seo
      ogFullTitle
      title="Cookie Policy"
      description="Please read our cookie policy. Your privacy is important to us. Feel free to contact us with any questions."
    />
    <Container>
      <CookieContent />
    </Container>
  </Layout>
);

export default Page;
